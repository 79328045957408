<template>
  <v-container>
    <h1>Student Usage Overview</h1>
    <v-row>
      <v-col>
        <v-autocomplete
          :items="teachers" item-text="name" class="mr-4" return-object label="Teacher" solo
          v-model="selectedTeacher" @change="ChooseTeacher"
        ></v-autocomplete>
      </v-col>
      <v-col>        
        <v-autocomplete
          :items="classes" item-text="name" class="mr-4" return-object label="Class:" solo
          v-model="selectedClass" @change="ChooseClass"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <!-- Tabla de datos sin hide-default-footer-->
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1 mt-4"      
    >
    <template v-slot:header.lastMonthUsage="{ header }">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">{{ header.text }}</span>
        </template>
        <span>
          Need more use - menos de 300 mins <br />
          Good use - 300 a 400 <br />
          Excellent use - 400 mins a mas
        </span>
      </v-tooltip>
    </template>

      <template v-slot:item.lastMonthUsage="{ item }">
        <v-chip
          :color="getUsageColor(item.lastMonthUsage)"
          dark
        >
          {{ getUsageText(item.lastMonthUsage) }}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="showStatistics(item)">mdi-magnify</v-icon>        
      </template>

      <!-- Fila de promedio -->
      <template v-slot:body.append>
        <tr>
          <td colspan="3" class="text-right"><strong>Average usage:</strong></td>
          <td>
            <v-chip
              :color="getUsageColor(convertSecondsToMinutes(totalUsageAverage))"
              dark
            >
              {{ getUsageText((totalUsageAverage)) }}
            </v-chip>
          </td>
          <td></td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      max-width="700"
    >
      <v-card>
        <v-card-title class="text-h5">
          Monthly Usage Percentage
        </v-card-title>

        <v-card-text>
          <v-simple-table>
            <template v-slot:default>              
              <tbody>
                <tr>
                  <td>Student:</td>
                  <td>{{studentBar}}</td>
                </tr>
                <tr>
                  <td>Class:</td>
                  <td>{{clasBar}}</td>
                </tr>
                <tr>
                  <td>Teacher:</td>
                  <td>{{teacherBar}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card>
            <bar :chart-data="barChartMonths" :options="barChartOptions" :height="200" ref="barChart"/>
          </v-card>          
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="closeBar"
          >
            Ok
          </v-btn>          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>  
</template>
<script>
import { auth, db } from "../../../plugins/firebase";
import Bar from '../../../components/BarChart';
export default {
  components: {
    Bar
  },
  data() {
    return {
      selectedTeacher: null,
      selectedClass: null,
      reference: '',
      data: [],
      teachers: [{id: 'All', name: 'All'}],
      classes: [{id: 'All', name: 'All'}],
      headers: [        
        { text: 'Student', value: 'student' },
        { text: 'Teacher', value: 'teacher' },
        { text: 'Class', value: 'class' },
        { text: 'Last Month Usage', value: 'lastMonthUsage' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],      
      items: [],
      dialog: false,
      barChartOptions: {
        responsive: true,
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      },      
      barChartMonths: {},
      studentBar: '',
      clasBar: '',
      teacherBar: '',
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
    };
  },
  created() {},
  methods: {
    getUsageText(usage) {      
      if (usage<300)
        return 'Needs support';
      else if (usage>=300 && usage<400)
        return 'Good';
      else if (usage>=400)
        return 'Excellent';
    },
    getUsageColor(usage) {
      if (usage<300)
          return 'needs-support';
      else if (usage>=300 && usage<400)
          return 'good';
      else if (usage>=400)
          return 'excellent'; 
    },
    generateBar(filtered){
      let newLabels = [];
      let newData = [];
      filtered.forEach((dataTime) => {console.log(this.months[dataTime.index-1]);
        newLabels.push(this.months[dataTime.index - 1]);
        newData.push(this.convertSecondsToMinutes(dataTime.value));
      });      
      /*this.barChartMonths.labels= newLabels;
      this.barChartMonths.datasets[0].data= newData; */
      this.barChartMonths={
        labels:  newLabels,
        datasets: [
          {
            label: 'Use time per month in minutes.',            
            data: newData,
            backgroundColor: '#6D9EEB'
          }
        ]
      }     
    },
    closeBar(){
      this.dialog = false;      
      this.barChartMonths.labels= [];
      this.barChartMonths.datasets[0].data= [];   
    },
    /*async getData(){
      const id=localStorage.getItem('uid');
      let info = await db.collection('SchoolAdmin').doc(id).get();
      this.reference=info.data().Reference;
      const schoolData=await db.doc(this.reference.path).get();
      const teacherData=Object.keys(schoolData.data().SchoolTeachers);
      for (const idt of teacherData) {
        let teacher=schoolData.data().SchoolTeachers[idt].Name+' '+schoolData.data().SchoolTeachers[idt].LastName;
        this.teachers.push({id: idt, name: teacher});
      }
    },*/
    async getData(){
      const id=localStorage.getItem('uid');
      let info = await db.collection('SchoolAdmin').doc(id).get();
      this.reference=info.data().Reference;
      const studentsDataPromise = db.doc(this.reference.path).collection('StudentsData').get();
      const [studentsDataSnapshot] = await Promise.all([studentsDataPromise]);
      const studentBookIndex = [];
      const studentBook = [];
      studentsDataSnapshot.forEach((doc) => {
        studentBookIndex.push(doc.id);
        studentBook.push(doc.data());
      });
      this.data=studentBook;

      studentBook.forEach((page) => {console.log("page");console.log(page);
        for (const studentId in page.Students){
          const student = page.Students[studentId];
          
          if (student.UserUIDSTeacher && student.TeacherFullName) {
            this.teachers.push({id: student.UserUIDSTeacher, name: student.TeacherFullName});
          }
        }
      });
    },
    /*async ChooseTeacher(){
      if (this.selectedTeacher.id!=='All'){
        this.classes=[{id: 'All', name: 'All'}];   
        let TempTeacherData = await db.collection('SchoolTeacher').doc(this.selectedTeacher.id).get();
        let classrooms=TempTeacherData.data().Classrooms;        
        let classNames = Object.keys(classrooms);console.log(classNames);
        for (const classUid of classNames) {
          this.classes.push({id: classUid, name: classrooms[classUid].Name});
        }
      }else{
        //Llamemos a studentsData
        
      }
    },*/
    async ChooseTeacher(){
      this.selectedClass=null; 
      this.classes=[{id: 'All', name: 'All'}];
      this.items=[];  
      this.data.forEach((page) => {
        for (const studentId in page.Students){
          const student = page.Students[studentId];
          
          if (student.AutoIdClassroom && student.ClassroomName && (this.selectedTeacher.id==='All' || student.UserUIDSTeacher===this.selectedTeacher.id)) {
            let key=student.AutoIdClassroom, name=student.ClassroomName;
            if (this.selectedTeacher.id==='All'){
              key = `${student.AutoIdClassroom}@${student.UserUIDSTeacher}`;
              name = `${student.ClassroomName} / ${student.TeacherFullName}`;
            }
            
            this.classes.push({id: key, name: name});            
          }
        }
      });
      console.log("this.classes");console.log(this.classes);      
    },
    /*async ChooseClass(){
      this.items=[];
      let now = new Date();
      let lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      let lastMonthFormatted = lastMonth.getFullYear() * 100 + (lastMonth.getMonth() + 1);      
      console.log(lastMonthFormatted);
      let TempClassData = await db.collection('SchoolTeacher').doc(this.selectedTeacher.id).collection('Classrooms').doc(this.selectedClass.id).get();
      console.log(TempClassData.data());
      let students=TempClassData.data().MainGroup;
      let studentIds=Object.keys(students);
      for (const student of studentIds){
        let studentName=students[student].Name+' '+students[student].LastName;
        let lastMonthUsage=0;
        let monthsUsageTime=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        if (students[student].hasOwnProperty('MonthlyTimeUse') && students[student].MonthlyTimeUse!=null){
          let datesTimeUse=Object.keys(students[student].MonthlyTimeUse);          
          for (const dateTimeUse of datesTimeUse){
            monthsUsageTime[parseInt(dateTimeUse.substr(4, 2), 10)]+=students[student].MonthlyTimeUse[dateTimeUse];
            if (dateTimeUse.includes(lastMonthFormatted))
              lastMonthUsage+=students[student].MonthlyTimeUse[dateTimeUse];
          }
        }
        this.items.push({student: studentName, teacher: this.selectedTeacher.name, class: this.selectedClass.name, lastMonthUsage: this.convertSecondsToMinutes(lastMonthUsage), monthsUsageTime: monthsUsageTime});
      }
      console.log(this.items);
    },*/
    async ChooseClass(){
      let uidTeacher=this.selectedTeacher.id;
      let uidClass=this.selectedClass.id;
      if (this.selectedClass.id.includes('@')){
        let uid=this.selectedClass.id.split('@');
        uidTeacher=uid[1];
        uidClass=uid[0];
      }console.log(uidTeacher);console.log(uidClass);
      this.items=[];
      let now = new Date();
      let lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      let lastMonthFormatted = lastMonth.getFullYear() * 100 + (lastMonth.getMonth() + 1);      
      console.log(lastMonthFormatted);
      this.data.forEach((page) => {console.log("page");console.log(page);
        for (const studentId in page.Students){
          const student = page.Students[studentId];
          if ((student.UserUIDSTeacher===uidTeacher || uidTeacher==='All') && (student.AutoIdClassroom===uidClass || uidClass==='All')){
            let studentName=`${student.Name} ${student.LastName}`;
            let lastMonthUsage=0;
            let monthsUsageTime=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            if (student.hasOwnProperty('MonthlyTimeUse') && student.MonthlyTimeUse!=null){
              let datesTimeUse=Object.keys(student.MonthlyTimeUse);
              for (const dateTimeUse of datesTimeUse){
                monthsUsageTime[parseInt(dateTimeUse.substr(4, 2), 10)]+=student.MonthlyTimeUse[dateTimeUse];
                if (dateTimeUse.includes(lastMonthFormatted))
                  lastMonthUsage+=student.MonthlyTimeUse[dateTimeUse];
              }
            }
            this.items.push({student: studentName, teacher: student.TeacherFullName, class: student.ClassroomName, lastMonthUsage: this.convertSecondsToMinutes(lastMonthUsage), monthsUsageTime: monthsUsageTime});
          }          
        }
        console.log(this.items);
      });
    },
    convertSecondsToMinutes(seconds) {    
      const minutes = Math.floor(seconds / 60);
      return minutes;
    },
    showStatistics(item){
      this.studentBar=item.student;
      this.clasBar=item.class;
      this.teacherBar=item.teacher;
      this.dialog=true;
      let filtered = item.monthsUsageTime.map((value, index) => ({ value, index })).filter(i => i.value !== 0);
      console.log(filtered);
      this.generateBar(filtered);
    }
  },
  mounted() {
    this.getData();    
  },
  computed: {
    totalUsageAverage() {
      var sumOfTimes=0; 
      this.items.forEach(item => {        
        sumOfTimes+=item.lastMonthUsage;
      });
      const totalItems = this.items.length;
      let average=sumOfTimes/totalItems;
      console.log("average-> "+average); 
      return average;
    }
  },
};
</script>
<style scoped>
  .v-alert {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .theme--dark.v-chip:not(.v-chip--active) {
    background: #555;
    color: #000;
    display: block;
    width: 50%;
    text-align: center;
    border: 1px solid #838280 !important;
  }
  .good{
    background-color: #ffe63c !important;
  }
  .excellent{
    background-color: #72c883 !important;
  }
  .needs-support{
    background-color: #f39ca2 !important;
  }
  
</style>