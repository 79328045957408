var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v("Student Usage Overview")]),_c('v-row',[_c('v-col',[_c('v-autocomplete',{staticClass:"mr-4",attrs:{"items":_vm.teachers,"item-text":"name","return-object":"","label":"Teacher","solo":""},on:{"change":_vm.ChooseTeacher},model:{value:(_vm.selectedTeacher),callback:function ($$v) {_vm.selectedTeacher=$$v},expression:"selectedTeacher"}})],1),_c('v-col',[_c('v-autocomplete',{staticClass:"mr-4",attrs:{"items":_vm.classes,"item-text":"name","return-object":"","label":"Class:","solo":""},on:{"change":_vm.ChooseClass},model:{value:(_vm.selectedClass),callback:function ($$v) {_vm.selectedClass=$$v},expression:"selectedClass"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"header.lastMonthUsage",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(" Need more use - menos de 300 mins "),_c('br'),_vm._v(" Good use - 300 a 400 "),_c('br'),_vm._v(" Excellent use - 400 mins a mas ")])])]}},{key:"item.lastMonthUsage",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getUsageColor(item.lastMonthUsage),"dark":""}},[_vm._v(" "+_vm._s(_vm.getUsageText(item.lastMonthUsage))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showStatistics(item)}}},[_vm._v("mdi-magnify")])]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"3"}},[_c('strong',[_vm._v("Average usage:")])]),_c('td',[_c('v-chip',{attrs:{"color":_vm.getUsageColor(_vm.convertSecondsToMinutes(_vm.totalUsageAverage)),"dark":""}},[_vm._v(" "+_vm._s(_vm.getUsageText((_vm.totalUsageAverage)))+" ")])],1),_c('td')])]},proxy:true}])}),_c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Monthly Usage Percentage ")]),_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',[_vm._v("Student:")]),_c('td',[_vm._v(_vm._s(_vm.studentBar))])]),_c('tr',[_c('td',[_vm._v("Class:")]),_c('td',[_vm._v(_vm._s(_vm.clasBar))])]),_c('tr',[_c('td',[_vm._v("Teacher:")]),_c('td',[_vm._v(_vm._s(_vm.teacherBar))])])])]},proxy:true}])}),_c('v-card',[_c('bar',{ref:"barChart",attrs:{"chart-data":_vm.barChartMonths,"options":_vm.barChartOptions,"height":200}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.closeBar}},[_vm._v(" Ok ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }